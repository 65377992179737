/**
 * Store configurations for the development environment. This is used when running the application in dev mode
 * This will add middleware that the store will use in the development environment. DO NOT, import this directly
 * when configuring the store with the root component for the application
 */
import { createStore } from "redux";

import { routerMiddleware } from "connected-react-router";
import rootReducer from "../reducers/rootReducer";
import DevTools from "./DevTools";
import { applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { persistConfig } from "./persistConfig";
import { prodMiddleware } from "./middleware";
import { history } from "./historyConfig";

// check if the reducx tools is activated in the window
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

/**
 * Configures the application store
 * */
export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...prodMiddleware, routerMiddleware(history))
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/rootReducer", () => {
      const nextRootReducer = require("../reducers/rootReducer").default;
      store.replaceReducer(rootReducer, nextRootReducer);
      DevTools.instrument();
    });
  }

  let persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}
