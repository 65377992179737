import React, { useState } from "react";
import {
  TextField,
  BaseTextFieldProps,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "./styles";
import CustomTooltipWrapper from "components/Tooltip/CustomTooltipWrapper";
import sqlFormatter from "sql-formatter";
import { TInputAttributeLang } from "model/application/Lang";
import _ from "lodash";

const useStyles = makeStyles(styles as any);

export enum INPUT_TEXT_TYPE {
  TEXT = "TEXT",
  SQL = "SQL",
  PHONE = "PHONE",
  INTEGER = "INTEGER",
  DECIMAL = "DECIMAL",
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
}

export interface ICustomInputTextProps
  extends Omit<BaseTextFieldProps, "lang" | "error"> {
  lang?: TInputAttributeLang;
  defaultValue?: string | number;
  onChange: (value: any, name: string) => void;
  type?: INPUT_TEXT_TYPE;
  forcedValue?: string;
  required?: boolean;
  isSearchField?: boolean;
  smallDisplay?: boolean;
  isUndetermined?: boolean;
  error?: string;
  name: string;
  darkMode?: boolean;
  showPasswordButton?: boolean;
  marginN?: "dense" | "normal";
  multiline?: boolean;
  disabled?: boolean;
  pattern?: string;
  lowercase?: boolean;
}

export const CustomInputText: React.FunctionComponent<
  ICustomInputTextProps
> = ({
  onChange,
  name,
  defaultValue,
  isUndetermined: isUndeterminedProps,
  type = INPUT_TEXT_TYPE.TEXT,
  error = "",
  lang,
  label,
  required = false,
  smallDisplay = false,
  isSearchField = false,
  forcedValue,
  darkMode = false,
  showPasswordButton = false,
  margin = "dense",
  multiline = false,
  disabled = false,
  pattern,
  lowercase,
}: ICustomInputTextProps) => {
  const classes = useStyles();

  const [value, setValue] = useState(defaultValue);
  const [showPassword, setShowPassword] = useState(false);
  const [isUndetermined, setIsUndetermined] = useState(isUndeterminedProps);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event: any) => {
    let err = false;

    if (type === INPUT_TEXT_TYPE.INTEGER) {
      if (!Number.isInteger(Number(event.target.value))) {
        err = true;
      }
    }
    let value: string =
      lowercase && _.isString(event.target.value)
        ? _.toLower(event.target.value)
        : event.target.value;
    if (type === INPUT_TEXT_TYPE.PHONE) {
      value = value.replace(/\D/g, "");
      value = "+" + value;
    } else if (
      type === INPUT_TEXT_TYPE.SQL &&
      value[value.length - 1] === ";"
    ) {
      const split = value.split("$");
      const params = split.filter((e, i) => i % 2 === 1);
      const temp = split.filter((e, i) => i % 2 === 0).join("xxxxx");
      value = sqlFormatter.format(temp);
      const valueSplitted = value.split("xxxxx");
      params.forEach((p, i) => {
        valueSplitted[i] = `${valueSplitted[i]}$${p}$`;
      });
      value = valueSplitted.join("");
    }
    if (!err) {
      setValue(value);
      setIsUndetermined(false);
      if (onChange) {
        if (type === INPUT_TEXT_TYPE.DECIMAL) {
          onChange(Number.parseFloat(value), name);
        } else if (type === INPUT_TEXT_TYPE.INTEGER) {
          onChange(Number.parseInt(value), name);
        } else onChange(value, name);
      }
    }
  };

  if (forcedValue) {
    return (
      <CustomTooltipWrapper
        errorMessage={error}
        title={lang ? lang.tooltip : undefined}
        disableFocusListener={!lang || !lang.tooltip}
        disableHoverListener={!lang || !lang.tooltip}
      >
        <TextField
          variant="outlined"
          label={lang ? lang.title : label}
          error={error.length > 0}
          InputProps={{
            className: isUndetermined
              ? classes.undeterminedInput
              : classes.input,
            classes: {
              root: darkMode ? classes.inputDarkMode : classes.input,
              notchedOutline: darkMode ? classes.inputDarkMode : classes.input,
            },
          }}
          InputLabelProps={{
            className: darkMode ? classes.inputDarkMode : classes.input,
          }}
          value={isUndetermined ? "Several values..." : forcedValue}
          autoFocus
          margin={margin}
          fullWidth
          required={required}
        />
      </CustomTooltipWrapper>
    );
  }
  const classNames = [];
  if (isSearchField) {
    if (smallDisplay) {
      classNames.push(classes.smallDisplay);
    }
    classNames.push(classes.textField);
  }
  let className = "";
  classNames.forEach((c) => {
    className += " " + c;
  });
  return (
    <CustomTooltipWrapper
      errorMessage={error}
      title={lang ? lang.tooltip : undefined}
      disableFocusListener={!lang || !lang.tooltip}
      disableHoverListener={!lang || !lang.tooltip}
    >
      <TextField
        InputProps={{
          classes: {
            root: darkMode ? classes.inputDarkMode : classes.input,
            notchedOutline: darkMode ? classes.inputDarkMode : classes.input,
          },
          endAdornment: (
            <InputAdornment position="end">
              {showPasswordButton ? (
                <IconButton
                  aria-label="toggle password visibility"
                  style={{ color: darkMode ? "white" : "black" }}
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          className: darkMode ? classes.inputDarkMode : classes.input,
        }}
        multiline={type === INPUT_TEXT_TYPE.SQL || multiline}
        className={className}
        variant="outlined"
        label={lang ? lang.title : label}
        error={error.length > 0}
        value={isUndetermined ? "Several values..." : value}
        type={
          !isUndetermined &&
          (type === INPUT_TEXT_TYPE.INTEGER || type === INPUT_TEXT_TYPE.DECIMAL)
            ? "number"
            : type === INPUT_TEXT_TYPE.PASSWORD
            ? showPassword
              ? "text"
              : "password"
            : type.toLowerCase()
        }
        margin={margin}
        onChange={handleChange}
        fullWidth
        required={required}
        disabled={disabled}
        inputProps={{ pattern: pattern || null }}
      />
    </CustomTooltipWrapper>
  );
};

export default CustomInputText;
